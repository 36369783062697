import React, { memo } from 'react';
// import 'swiper/scss';
import { LazyGetTriggerType } from 'features/slotArchive/actions';
import { CardTypes } from '../../cards/types';
import { SectionTitleType } from '../../sectionTitle';
import { Wrapper } from '../../wrapper';
import { CardType, ConditionalCard } from '../components/conditionalCard/ConditionalCard';
import styles from './DefaultCarousel.module.scss';
import { Carousel } from 'components/carousel/Carousel';
import { shallowEqual } from 'fast-equals';

export type DefaultCarouselProps<T extends CardTypes> = Omit<SectionTitleType, 'nopadding' | 'className'> & {
  typeOfItem: CardType;
  listItem: T[];
  triggerRefetch?: LazyGetTriggerType;
};

export const DefaultCarousel: React.FC<DefaultCarouselProps<CardTypes>> = memo(
  function DefaultCarousel({
    title,
    subtitle,
    url,
    typeOfItem,
    listItem,
    triggerRefetch,
  }: DefaultCarouselProps<CardTypes>) {
    return (
      <Wrapper noPaddingLeft noPaddingRight>
        <Carousel
          title={title}
          classNameTitle={styles.title}
          subtitle={subtitle}
          classNameSubtitle={styles.paddingLeft}
          url={url}
          cardContainerClassName={styles.cardContainerSlot}
        >
          {listItem &&
            listItem.map((item, index) => {
              return (
                <ConditionalCard
                  key={item.id + '_' + index}
                  type={typeOfItem}
                  item={item}
                  triggerRefetch={triggerRefetch}
                />
              );
            })}
        </Carousel>
      </Wrapper>
    );
  },
  function areEqual(prevProps, nextProps) {
    return (
      prevProps.title === nextProps.title &&
      prevProps.subtitle === nextProps.subtitle &&
      prevProps.url === nextProps.url &&
      prevProps.typeOfItem === nextProps.typeOfItem &&
      shallowEqual(prevProps.listItem, nextProps.listItem) &&
      prevProps.triggerRefetch === nextProps.triggerRefetch
    );
  }
);
